import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/iX9KbvB-qcQ">
    <SEO title="A New Perspective - Hebrews" />
  </Layout>
)
export default SermonPost
